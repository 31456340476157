<template>
<el-dialog v-model="visible" title="查看详情" :before-close="close" custom-class="text-report-detail-dialog">
  <div class="trdd-body" v-loading="loading">
    <template v-if="Object.keys(dialogData).length > 0">
      <div class="body-title">{{dialogData.baseInfo.txtClinicName}} 检测报告单</div>
      <div class="body-base-info">
        <div class="common-title bbi-title">基本信息</div>
        <table>
          <tr>
            <td>姓名：{{detailData.txtUsername || "- -"}}</td>
            <!-- <td>性别：{{detailData.intGender==0?'女':'男'}}</td>
            <td>年龄：{{detailData.intAge || "- -"}}</td> -->
            <td>联系方式：{{detailData.txtMobile || "- -"}}</td>
          </tr>
          <tr>
            <td>检测项目：{{detailData.txtTpName}}</td>
            <td>采集时间：{{dialogData.baseInfo.dtGather}}</td>
          </tr>
        </table>
      </div>
      <div class="test-detail-part">
        <div class="common-title test-title">{{ dialogData.baseInfo.txtTpName}}</div>
        <template v-if="dialogData.wbc">
          <div class="tdp-wbc-part">
            <div class="tdp-wbc-pt-title-line row-center-between">
              <div>项目：{{ dialogData.wbc.txtTpName }}</div>
              <div>检测时间：{{ dialogData.wbc.dtDetect }}</div>
            </div>
            <table>
              <tr>
                <th>参数</th>
                <th>结果</th>
                <th>参考区间</th>
              </tr>
              <tr v-for="(item, index) in dialogData.wbc.results" :key="index" v-show="item.printable">
                <td class="text-bold">
                  <div>{{ item.txtItemCode }}</div>
                  <div>({{ item.txtItemName }})</div>
                </td>
                <td class="text-bold" :class="item.txtResult=='L'?'text-red':item.txtResult=='H'?'text-green':''">{{ item.txtValue }}</td>
                <td>{{ item.txtRange }}</td>
              </tr>
            </table>
            <!-- 渲染图 -->
            <div class="tdp-wbc-p-chart-part" v-if="chartShow">
              <div class="tdp-wbc-p-line-chart" id="lineChart"></div>
            </div>
          </div>
        </template>
        <template v-if="dialogData.cg">
          <div class="tdp-item" v-for="(item, index) in dialogData.cg" :key="index">
            <table>
              <tr>
                <th>项目：{{ item.txtCategoryName || "- -" }}（{{ item.txtCategoryDesc }}）</th>
                <th></th>
                <th>
                  <div v-if="item.txtCategoryTitle1">{{item.txtCategoryTitle1}}：{{item.txtDetectResult1}}</div>
                  <div v-if="item.txtCategoryTitle2">{{item.txtCategoryTitle2}}：{{item.txtDetectResult2}}</div>
                </th>
                <th>结果：{{ item.txtResultValue }}</th>
              </tr>
              <tr>
                <td colspan="2">参考区间：{{item.txtValueRange||"- -"}}</td>
                <td colspan="2">检测时间：{{item.dtDetect}}</td>
              </tr>
            </table>
            <div class="tdp-progress">
              <div class="tdp-result" id="tdp-result1" :style="{marginLeft: compWidth(item.txtParamsRange1, item.fltDetectResult1, 'pointerLine_1')}">{{ item.txtCategoryTitle1 }}： {{item.txtDetectResult1}}</div>
              <div class="tdpp-line row-center-center">
                <img src="../../../assets/imgs/pointer.png" :style="{left: compWidth(item.txtParamsRange1, item.fltDetectResult1, 'pointer')}">
                <div class="tdpp-progress-line"></div>
              </div>
              <div class="tdpp-line-num row-center-start">
                <div v-for="(sItem, sIndex) in item.txtParamsRange1.split('/')" :key="sIndex" :class="sIndex > 1 ? 'absoPosition': ''" :style="[{width: sIndex == 0 ? compWidth(item.txtParamsRange1, item.fltDetectResult1, sIndex) : ''}, {left: (sIndex > 1 && sIndex < item.txtParamsRange1.split('/').length-1) ? compWidth(item.txtParamsRange1, item.fltDetectResult1, sIndex) : ''}]">{{ sItem }}</div>
              </div>
            </div>
            <div class="tdp-progress" v-if="(item.txtParamsRange2||'') != ''">
              <div class="tdp-result" id="tdp-result2" :style="{marginLeft: compWidth(item.txtParamsRange2, item.fltDetectResult2, 'pointerLine_2')}">{{ item.txtCategoryTitle2 }}： {{item.txtDetectResult2}}</div>
              <div class="tdpp-line row-center-center">
                <img src="../../../assets/imgs/pointer.png" :style="{left: compWidth(item.txtParamsRange2, item.fltDetectResult2, 'pointer')}">
                <div class="tdpp-progress-line"></div>
              </div>
              <div class="tdpp-line-num row-center-start">
                <div v-for="(sItem, sIndex) in item.txtParamsRange2.split('/')" :key="sIndex" :class="sIndex > 1 ? 'absoPosition': ''" :style="[{width: sIndex == 0 ? compWidth(item.txtParamsRange2, item.fltDetectResult2, sIndex) : ''}, {left: (sIndex > 1 && sIndex < item.txtParamsRange1.split('/').length-1) ? compWidth(item.txtParamsRange2, item.fltDetectResult2, sIndex) : ''}]">{{ sItem }}</div>
              </div>
            </div>
            <div class="tdp-pro-unscramble">
              <div>项目解读：</div>
              <div>{{item.txtCategoryInterpreted}}</div>
            </div>
            <div class="tdp-pro-unscramble">
              <div>智能解析：</div>
              <div>{{item.txtResultAnalysis}}</div>
            </div>
          </div>
        </template>
      </div>
      <div class="user-textarea-part">
        <div class="common-title utp-title">
          病人主诉
          <el-button type="primary" size="small" @click="dialogData.baseInfo.txtPatientDescription=null">清空</el-button>
        </div>
        <el-input v-model="dialogData.baseInfo.txtPatientDescription" maxlength="400" show-word-limit :rows="3" type="textarea" placeholder="请填写患者本次就诊的主要症状（或体征）、持续时间等" />
      </div>
      <div class="user-textarea-part">
        <div class="common-title utp-title">
          医嘱建议
          <el-button type="primary" size="small" @click="dialogData.baseInfo.txtDoctorSuggestion=null">清空</el-button>
        </div>
        <el-input v-model="dialogData.baseInfo.txtDoctorSuggestion" maxlength="400" show-word-limit :rows="3" type="textarea" placeholder="请填写病人在饮食、用药、化验等方面的指示" />
      </div>
      <div class="user-textarea-part row-center-start">
        <div class="common-title utp-title no-margin">医生签字</div>
        <el-input class="doctor-input" clearable v-model="dialogData.baseInfo.txtDoctorSignature" minlength="2" maxlength="10" placeholder="请填写医生姓名" />
        <div class="input-hint" v-if="hintShow">最少输入两个字符</div>
      </div>
    </template>
    <div v-else class="no-data column-center-center">暂无数据</div>
  </div>
  <template #footer v-if="Object.keys(dialogData).length > 0">
    <div class="dialog-footer">
      <el-button type="info" @click="close()">取 消</el-button>
      <el-button type="primary" @click="handleSubmit()" v-preventReClick>确 定</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
import { ElMessage } from "element-plus";
import { getOrderDetailById, updateCase } from "api/apis.js";
import { onMounted, reactive, toRefs, inject } from 'vue';
export default {
  props: {
    detailData: { type: Object }
  },
  setup(prop, ctx) {
    // 引入echarts
    let echart = inject("echart");
    const state = reactive({
      visible: true,
      loading: false,
      dialogData: {},
      hintShow: false,
      chartData: null,
      chartShow: true
    });

    onMounted(() => {
      getDetailData();
    })

    // 保存
    const handleSubmit = () => {
      if ((state.dialogData.baseInfo.txtDoctorSignature || "").trim() != "") {
        if (state.dialogData.baseInfo.txtDoctorSignature.length < 2) {
          state.hintShow = true;
        } else {
          state.hintShow = false;
        }
      }
      if (state.hintShow == false) {
        state.loading = true;
        updateCase({
          orderId: prop.detailData.orderId,
          deviceTypeCode: prop.detailData.deviceTypeCode,
          txtPatientDescription: state.dialogData.baseInfo.txtPatientDescription,
          txtDoctorSuggestion: state.dialogData.baseInfo.txtDoctorSuggestion,
          txtDoctorSignature: state.dialogData.baseInfo.txtDoctorSignature
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success("保存成功");
            close();
          } else {
            ElMessage.error(response.message);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.loading = false;
        })
      }
    };

    // 获取详细信息
    const getDetailData = () => {
      state.loading = true;
      getOrderDetailById({
        orderId: prop.detailData.orderId,
        // orderId: 914,
        deviceTypeCode: prop.detailData.deviceTypeCode
      }).then(response => {
        if (response.code == 200) {
          state.dialogData = response.data;
          if (state.dialogData.wbc) {
            if ((state.dialogData.wbc.histInfo.hist || []).length > 0) {
              state.chartData = state.dialogData.wbc.histInfo;
              state.chartShow = true;
              setTimeout(() => {
                initChart();
              }, 1000)
            } else {
              state.chartShow = false;
              state.chartData = [];
            }
          } else {
            state.chartShow = false;
            state.chartData = [];
          }
        } else {
          state.chartShow = false;
          state.dialogData = {};
          state.chartData = [];
          ElMessage.error(response.message);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    // 动态计算高度
    const compWidth = (result, detect, type) => {
      let tempResult = result.split("/"),
        end = parseFloat(tempResult[tempResult.length - 1]);
      if (typeof type == "string") {
        let pointer = parseFloat(detect);
        // 超过 50%，橘色条 显示在左边，防止右侧 UI 溢出
        let marginLeft = (Math.round(pointer / end * 10000) / 100.00);
        // 如果 值 > 100, 则改为 100， 防止位置过界（在视角之外）
        if (marginLeft > 100) {
          marginLeft = 100;
        }

        if (type == "pointer") {
          if (marginLeft == 100) {
            return "calc(" + parseFloat(marginLeft) + "% - 18px)";
          } else {
            return marginLeft + "%";
          }
        }

        if (marginLeft > 50 && type.includes("pointerLine")) {
          let key = type.split("_")[1],
            orange = document.getElementById("tdp-result" + key);
          if (orange) {
            orange = orange.offsetWidth - 16;
          } else {
            orange = 0;
          }

          return "calc(" + parseFloat(marginLeft) + "% - " + orange + "px)";
        } else {
          return marginLeft + "%";
        }
      } else {
        // 不管长度为多少，除 0 以外 第一个数，都这样计算
        let width = Math.round(tempResult[1] / end * 10000) / 100.00;
        if (type == 0) {
          return width + "%";
        }
        // 判断 区间长度
        // 如果三位数
        if (tempResult.length == 3) {
          if (type == 1) {
            return (100 - width) + "%";
          }
        } else {
          let left = Math.round(tempResult[type] / end * 10000) / 100.00;
          return left + "%";
        }
      }
    };

    // 渲染图表
    const initChart = () => {
      echart.init(document.getElementById("lineChart")).dispose();
      let myChart = echart.init(document.getElementById("lineChart"));
      myChart.setOption({
        grid: {
          left: "4%",
          right: "4%",
          top: "4%",
          bottom: "4%"
        },
        xAxis: {
          type: 'category',
          axisTick: { show: false },
          axisLabel: { show: false },
          axisLine: { lineStyle: { color: "#7d8687" } }
        },
        yAxis: { type: 'value', show: false },
        series: [{
          type: 'line',
          smooth: 0.6,
          symbol: 'circle',
          emphasis: { disabled: true },

          markLine: {
            symbol: ['none', 'none'],
            label: { show: false },
            emphasis: { disabled: true },
            lineStyle: { color: "#9fa9a8" },
            data: [{ xAxis: state.chartData.lymEndIndex }, { xAxis: state.chartData.midEndIndex }]
          },
          areaStyle: { color: "#c5d8d8" },
          lineStyle: { color: "#a6abac" },
          data: state.chartData.hist.map(t => { return { value: t, name: "" } })
        }]
      })
    };

    // dialog 关闭事件
    const close = () => {
      ctx.emit("close");
    };

    return {
      ...toRefs(state),
      close,
      compWidth,
      getDetailData,
      handleSubmit,
      initChart
    }
  }
}
</script>

<style lang="scss">
.text-report-detail-dialog {
  width: 900px;
  height: calc(100% - 25vh);

  .el-dialog__header {
    font-weight: bold;
  }

  .el-dialog__body {
    padding: 15px 20px;
    height: calc(100% - 120px);
    box-sizing: border-box;

    .no-data {
      height: 100%;
      width: 100%;
    }

    .trdd-body {
      height: 100%;

      .common-title {
        font-size: 16px;
        font-weight: bold;

        .el-button {
          margin-left: 4px;
        }
      }

      .body-title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
      }

      .body-base-info {
        margin-top: 6px;

        table {
          margin: 6px 0;
          padding: 6px 6px;
          border-top: 1px solid #f6f3ef;
          border-bottom: 1px solid #f6f3ef;
          width: 100%;

          tr {
            td {
              padding: 8px 0;
              width: calc(100% / 4);
              letter-spacing: 1px;
            }
          }
        }
      }

      .test-detail-part {
        .test-title {
          text-align: center;
          margin: 12px 0 8px 0;
        }

        .tdp-wbc-part {
          margin: 6px 0;
          padding: 6px 6px;
          width: 100%;
          box-sizing: border-box;

          .tdp-wbc-p-chart-part {
            height: 300px;

            .tdp-wbc-p-line-chart {
              height: 100%;
            }
          }

          .tdp-wbc-pt-title-line {
            padding: 6px 6px;

            div {
              &:first-child {
                font-weight: bold
              }
            }
          }

          table {
            margin: 6px 0;
            width: 100%;

            tr {
              th {
                color: #ffffff;
                padding: 8px 0;
                width: 28%;
                background-color: #70bffe;
              }

              td {
                text-align: center;
                padding: 8px 0;
                background-color: #daeeff;

                &.text-bold {
                  font-weight: bold;
                }

                &.text-red {
                  color: #ff0000;
                }

                &.text-green {
                  color: #67c23a;
                }

                div {
                  &:last-child {
                    font-weight: 400;
                    color: #b1b3b8;
                  }
                }
              }
            }
          }
        }

        .tdp-item {
          padding-bottom: 26px;
          border-bottom: 1px solid #f6f3ef;

          table {
            margin: 6px 0;
            padding: 6px 6px;
            width: 100%;
            table-layout: fixed;

            tr {

              th,
              td {
                padding: 4px 0;
                width: calc(100% / 4);
                letter-spacing: 1px;
                text-align: left;
              }
            }
          }

          .tdp-progress {
            .tdp-result {
              color: #ffffff;
              padding: 4px 8px;
              background-color: #e6a23c;
              border-radius: 10px;
              font-size: 14px;
              margin-bottom: 12px;

              // todo
              width: max-content;
              min-width: 100px;
            }

            .tdpp-line {
              width: 100%;
              height: 16px;
              position: relative;

              img {
                position: absolute;
                bottom: 40%;
              }

              .tdpp-progress-line {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                background-image: linear-gradient(to right, #d4ecff, #6fbffe);
              }
            }

            .tdpp-line-num {
              position: relative;

              div {
                &:last-child {
                  position: absolute;
                  right: 0;
                }

                &.absoPosition {
                  position: absolute;
                }
              }
            }
          }

          .tdp-pro-unscramble {
            padding: 0 6px;
            margin-top: 18px;

            div {
              &:first-child {
                margin: 8px 0;
              }

              &:last-child {
                letter-spacing: 4px;
                line-height: 1.5;
              }
            }
          }
        }
      }

      .user-textarea-part {
        margin-top: 16px;

        .utp-title {
          margin-bottom: 8px;
        }

        .no-margin {
          margin: 0;
        }

        .doctor-input {
          width: 200px;
          margin-left: 14px;
        }
      }

      .input-hint {
        color: #ad373d;
        margin-left: 8px;
      }
    }
  }
}
</style>
